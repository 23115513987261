const ErrorPopup = ({ isOpen, onClose }) => {
  const handleSelect = () => {
    onClose();
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-6">
        <h2 className="text-2xl font-bold text-emerald-700 mb-4">오류</h2>
        <div style={{ marginTop: "15px" }}>
          <p>분석 중 에러가 발생했습니다.</p>
        </div>
        <button
          className="w-full bg-emerald-500 text-white py-3 rounded-xl font-semibold text-lg hover:bg-emerald-600 transition duration-300"
          onClick={handleSelect}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;
