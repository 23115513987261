import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logoIcon from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AppContext";
import { useApi } from "./ApiProvider";
import { handleGetLoginError } from "../Utils/ErrorHandler";
import ServerStatus from "./ServerStatus";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
`;

const LoginBox = styled.div`
    background: #fff;
    padding: 10px 10px 40px 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 360px;
    text-align: center;
`;

const LoginForm = styled.form`
    padding-right: 30px;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;

    &:focus {
        outline: none;
        border-color: rgb(88, 185, 158);
    }

    &:not(:placeholder-shown) {
        background-color: rgb(88, 185, 158, 0.1);
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
`;

const Checkbox = styled.input`
    margin-right: 8px;
`;

const Button = styled.button`
    width: 100%;
    padding: 12px;
    background: linear-gradient(90deg, #1abc9c, #16a085);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const LogoImage = styled.img`
    width: 90%;
    max-width: 300px;
    height: auto;
    margin-bottom: 16px;
`;

const LoginFailedDiv = styled.div`
    padding: 16px;
    background: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    margin-bottom: 16px;
    font-weight: bold;
    box-sizing: border-box;
`;

function LoginPage() {
    const navigate = useNavigate();
    const { fetchWithoutToken } = useApi();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const { login } = useAuth();

    useEffect(() => {
        const savedUsername = localStorage.getItem("username");
        const savedPassword = localStorage.getItem("password");

        if (savedUsername && savedPassword) {
            setUsername(savedUsername);
            setPassword(savedPassword);
            setRememberMe(true);
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (rememberMe) {
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
        } else {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
        }

        try {
            const response = await fetchWithoutToken(
                `${process.env.REACT_APP_API_URL}/api/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {
                        username: username,
                        password: password,
                    },
                    withCredentials: true, // credentials: 'include'에 해당
                }
            );

            if (response.status === 200) {
                // response.ok에 해당하는 axios의 대응 방법
                const data = response.data; // axios는 자동으로 JSON 파싱을 수행함

                if (data.access_token) {
                    sessionStorage.setItem("accessToken", data.access_token);
                    console.log("Access token saved:", data.access_token);
                } else {
                    console.error("Access token not found in the response");
                }

                login();
                navigate("/main");
            } else {
                console.error("else during login:");
            }
        } catch (error) {
            handleGetLoginError(error, setErrorMsg, setLoginFailed);
        }

        // if (id === "pineone" && pw === "pine1234") {
        //   login();
        //   navigate(`/main`);
        // } else {
        //   setLoginFailed(true);
        // }
    };

    return (
        <Container>
            {loginFailed && <LoginFailedDiv>{errorMsg}</LoginFailedDiv>}
            <LoginBox>
                <ServerStatus />
                <LogoImage src={logoIcon} alt="logoIcon" />
                <LoginForm onSubmit={handleLogin}>
                    <Input
                        type="text"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <Input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <CheckboxContainer>
                        <CheckboxLabel>
                            <Checkbox
                                type="checkbox"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />{" "}
                            Remember me
                        </CheckboxLabel>
                    </CheckboxContainer>
                    <Button type="submit">Sign In</Button>
                </LoginForm>
            </LoginBox>
        </Container>
    );
}

export default LoginPage;
