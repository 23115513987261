import React, { useEffect, useState, useRef } from "react";
import { Play, Pause } from "lucide-react";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

const AudioPlayer = ({ audioBlob }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioBlob) {
      let url;
      try {
        url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);

        if (audioRef.current) {
          audioRef.current.onloadedmetadata = () => {
            setDuration(audioRef.current.duration);
          };
        }
      } catch (error) {
        console.error("Error creating Blob URL:", error);
      }

      return () => {
        if (url) {
          URL.revokeObjectURL(url);
        }
        setAudioUrl(null);
      };
    }
  }, [audioBlob]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateTime = () => setCurrentTime(audio.currentTime);
      const handleEnded = () => {
        setIsPlaying(false);
        setCurrentTime(0);
      };

      audio.addEventListener("timeupdate", updateTime);
      audio.addEventListener("ended", handleEnded);

      return () => {
        audio.removeEventListener("timeupdate", updateTime);
        audio.removeEventListener("ended", handleEnded);
      };
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  if (!audioBlob) return null;

  return (
    <div className="bg-emerald-50 rounded-xl p-3 mb-4">
      {audioUrl && (
        <div className="flex items-center">
          <button
            className="w-10 h-10 bg-emerald-500 rounded-full flex items-center justify-center mr-4"
            onClick={handlePlayPause}
          >
            {isPlaying ? (
              <Pause className="w-5 h-5 text-white" />
            ) : (
              <Play className="w-5 h-5 text-white" />
            )}
          </button>
          <div className="flex-grow">
            <div className="bg-emerald-200 h-2 rounded-full">
              <div
                className="bg-emerald-500 h-full rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${(currentTime / duration) * 100}%` }}
              ></div>
            </div>
          </div>
          <span className="ml-4 text-sm text-emerald-700 font-medium">
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </div>
      )}
      <audio
        ref={audioRef}
        src={audioUrl}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
    </div>
  );
};

export default AudioPlayer;
