import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { globalModalActions } from "../../Action";

Modal.setAppElement("#root");

const GlobalModalComponent = ({ globalModal, dispatch }) => {
  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(24, 26, 27, 0.72)",
      zIndex: 999998,
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      border: "none",
      padding: 0,
      WebkitOverflowScrolling: "touch",
      borderRadius: "14px",
      width: "90%",
      maxWidth: "600px", // 최대 너비 설정
      height: "auto", // 자동 높이
      maxHeight: "90vh", // 최대 높이 설정
      background: "transparent", // 배경을 투명으로 설정하여 TailwindCSS 적용 가능
    },
  };

  const afterOpenModal = () => {
    document.body.classList.add("blocking-scroll");
    if (globalModal.onAfterOpen) {
      globalModal.onAfterOpen();
    }
  };

  const afterCloseModal = () => {
    document.body.classList.remove("blocking-scroll");
    if (globalModal.onAfterClose) {
      globalModal.onAfterClose();
    }
  };

  const closeModal = () => {
    dispatch(
      globalModalActions.globalModalValueSet({
        modalIsOpen: false,
      })
    );
  };

  return (
    <Modal
      style={modalStyle}
      isOpen={globalModal.modalIsOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={afterCloseModal}
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-2xl p-6">
          <h2 className="text-2xl font-bold text-emerald-700 mb-4">오류</h2>
          {globalModal.modalComponent && (
            <globalModal.modalComponent {...globalModal.modalProps} />
          )}
          <button
            className="w-full bg-emerald-500 text-white py-3 rounded-xl font-semibold text-lg hover:bg-emerald-600 transition duration-300"
            onClick={closeModal}
          >
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  globalModal: state.globalModal,
});

export default connect(mapStateToProps)(GlobalModalComponent);
