import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { handleGetUserMediaError } from "../Utils/ErrorHandler";
import { IoMicCircleOutline } from "react-icons/io5";

const AudioRecorder = ({ onRecordingComplete, onRecording }) => {
  const audioContextRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioChunksRef = useRef([]);

  const [isRecording, setIsRecording] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [waveformData, setWaveformData] = useState(new Array(32).fill(0));
  const [displayedWaveformData, setDisplayedWaveformData] = useState(
    new Array(20).fill(0)
  );
  const [elapsedTime, setElapsedTime] = useState("00:00");

  const dispatch = useDispatch();

  const updateWaveform = useCallback(() => {
    if (!analyserRef.current) return;

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    // 저주파 영역의 20개 구간만 선택
    const sampledData = new Array(6).fill(0).map((_, i) => dataArray[i]);

    // const sampledData = new Array(32)
    //     .fill(0)
    //     .map((_, i) => dataArray[Math.floor((i * dataArray.length) / 32)]);

    const normalizedData = sampledData.map((value) => value / 255);

    setWaveformData(normalizedData);
    setDisplayedWaveformData(normalizedData);

    // 32개의 데이터를 20개로 줄이기
    // const reducedData = [];
    // for (let i = 0; i < 20; i++) {
    //     const startIndex = Math.floor(i * 32 / 20);
    //     const endIndex = Math.floor((i + 1) * 32 / 20);
    //     const slice = normalizedData.slice(startIndex, endIndex);
    //     const average = slice.reduce((a, b) => a + b, 0) / slice.length;
    //     reducedData.push(average);
    // }
    // setDisplayedWaveformData(reducedData);

    animationFrameRef.current = requestAnimationFrame(updateWaveform);
  }, []);

  useEffect(() => {
    let intervalId;
    if (isRecording && recordingStartTime) {
      intervalId = setInterval(() => {
        const elapsed = (Date.now() - recordingStartTime) / 1000;
        const minutes = String(Math.floor(elapsed / 60)).padStart(2, "0");
        const seconds = String(Math.floor(elapsed % 60)).padStart(2, "0");
        const currentTime = `${minutes}:${seconds}`;
        setElapsedTime(currentTime);
        onRecording(currentTime);
      }, 100);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRecording, recordingStartTime, onRecording]);

  const startRecording = async () => {
    try {
      console.log("Starting new recording session...");

      if (audioContextRef.current) {
        await audioContextRef.current.close();
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaStreamRef.current = stream;
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);

      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;
      source.connect(analyserRef.current);

      await audioContextRef.current.audioWorklet.addModule(
        "/worklet-processor.js"
      );
      const myNode = new AudioWorkletNode(
        audioContextRef.current,
        "worklet-processor"
      );

      myNode.port.onmessage = (event) => {
        const audioData = event.data;
        audioChunksRef.current.push(audioData);
      };

      source.connect(myNode);
      myNode.connect(audioContextRef.current.destination);

      setIsRecording(true);
      audioChunksRef.current = [];
      setRecordingStartTime(Date.now());
      setElapsedTime("00:00");

      updateWaveform();
    } catch (error) {
      console.error("Error starting recording:", error);
      handleGetUserMediaError(error, dispatch, setIsRecording);
    }
  };

  const stopRecording = async () => {
    console.log("Stopping recording...");

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    if (audioContextRef.current) {
      await audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    const recordingDuration =
      audioChunksRef.current.reduce((acc, chunk) => acc + chunk.length, 0) /
      (audioContextRef.current ? audioContextRef.current.sampleRate : 44100);

    if (recordingDuration < 1) {
      const error = new Error("음성 길이가 너무 짧습니다.");
      error.name = "ShortRecordingError";
      handleGetUserMediaError(error, dispatch, setIsRecording);
      audioChunksRef.current = [];
    } else {
      const wavBlob = createWavBlob(audioChunksRef.current);
      downloadAudio(wavBlob);
      onRecordingComplete(wavBlob);
    }

    setIsRecording(false);
    setRecordingStartTime(null);
    setWaveformData(new Array(32).fill(0));
  };

  const createWavBlob = (audioChunks) => {
    const sampleRate = audioContextRef.current
      ? audioContextRef.current.sampleRate
      : 44100;
    const numChannels = 1;
    const bitsPerSample = 16;

    const dataLength =
      audioChunks.reduce((acc, chunk) => acc + chunk.length, 0) * 2;
    const buffer = new ArrayBuffer(44 + dataLength);
    const view = new DataView(buffer);

    // WAV 헤더 작성
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + dataLength, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numChannels * (bitsPerSample / 8), true);
    view.setUint16(32, numChannels * (bitsPerSample / 8), true);
    view.setUint16(34, bitsPerSample, true);
    writeString(view, 36, "data");
    view.setUint32(40, dataLength, true);

    // 오디오 데이터 작성
    let offset = 44;
    for (let chunk of audioChunks) {
      for (let i = 0; i < chunk.length; i++) {
        const sample = Math.max(-1, Math.min(1, chunk[i]));
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        offset += 2;
      }
    }

    return new Blob([buffer], { type: "audio/wav" });
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const downloadAudio = (wavBlob) => {
    const audioUrl = URL.createObjectURL(wavBlob);
    const a = document.createElement("a");
    a.href = audioUrl;
    a.download = "recorded_audio.wav";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleButtonClick = async () => {
    if (isRecording) {
      await stopRecording();
    } else {
      await startRecording();
    }
  };

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <div className="relative mb-4">
      <button
        className={`w-24 h-24 rounded-full flex items-center justify-center mx-auto relative z-10 transition-all duration-300 ${
          isRecording
            ? "bg-red-500 animate-pulse"
            : "bg-emerald-500 hover:bg-emerald-600"
        }`}
        onClick={handleButtonClick}
      >
        <IoMicCircleOutline className="w-12 h-12 text-white" />
      </button>
      <div className="flex flex-row justify-center items-center space-x-4">
        {isRecording && (
          <div className="waveform-container w-full max-w-[50px] h-[40px] rounded-lg flex items-center justify-between px-1">
            {displayedWaveformData.map((height, index) => (
              <div
                key={index}
                className="bar w-[7%] h-full bg-gradient-to-t from-emerald-600 to-emerald-400 rounded mx-[1px]"
                style={{
                  transform: `scaleY(${Math.max(0.2, height)})`,
                  transition: "transform 0.1s ease-in-out",
                }}
              />
            ))}
          </div>
        )}
        <div className="text-center text-lg font-semibold">
          {isRecording ? `Recording: ${elapsedTime}` : "Ready to Record"}
        </div>
      </div>
    </div>
  );
};

export default AudioRecorder;
