import React, { useState, useEffect, useRef } from "react";
import TableList from "./TableList";
import SelectPeoplePopup from "./SelectPeoplePopup";
import Dropzone from "./Dropzone";
import { Activity, Users } from "lucide-react";
import ErrorPopup from "./ErrorPopup";
import { useWebSocket } from "./WebSocketProvider";
import { useApi } from "./ApiProvider";

const EmotionRecognition = ({ audioBlob, handleDropFile }) => {
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isUploadingData, setIsUploadingData] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [analysisProgress, setAnalysisProgress] = useState(0);
    const resultRef = useRef(null);
    const cancelTokenSource = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [jsonList, setJsonList] = useState([]);
    const [progressMsg, setProgressMsg] = useState("");

    const { connectWebSocket, setOnOpen, setOnMessage, setOnError, setOnClose } = useWebSocket();
    const { fetchWithToken } = useApi();

    useEffect(() => {
        if (resultRef.current) {
            resultRef.current.innerText = "";
        }
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel("Request cancelled due to radioValue change.");
        }
    }, []);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
        if (jsonList.length > 0) {
            setJsonList([]);
        }
    };

    const handleConfirm = async (peopleCount) => {
        setIsPopupOpen(false);
        sendToServer(peopleCount);
    };

    const sendToServer = async (peopleCount) => {
        console.log("업로드 중");
        setIsFetchingData(true);
        setIsUploadingData(true);
        setUploadProgress(0);
        setAnalysisProgress(0);
        setIsAnalyzing(false);

        const formData = new FormData();
        const apiKey = "7bcf0162-9ceb-4bc9-8152-db7a93a370b3";
        formData.append("wav", audioBlob, "recording.wav");
        formData.append("number", peopleCount);

    try {
      setOnOpen(async () => {
        console.log("WebSocket connected");
        try {
          await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/emotion`, {
            method: "post",
            data: formData,
            headers: {
              "x-api-key": apiKey,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
              console.log(`Upload Progress: ${percentCompleted}%`);
              if (percentCompleted === 100) {
                setIsUploadingData(false);
                setIsAnalyzing(true);
              }
            },
          }).then((response) => {
            const data = response.data;
            if (data.data.status === "성공") {
              console.log("분석 성공:", data.data.result);
              setJsonList(data.data.result);
              setIsAnalyzing(false);
            } else if (data.data.status === "실패") {
              console.log("분석 실패:", data.data.result);
              setIsErrorPopupOpen(true);
              setIsFetchingData(false);
              setIsAnalyzing(false);
              handleDropFile(null);
            } else {
              console.log("뭐가 넘어온거야:", data.data.result);
            }
          });
        } catch (error) {
          console.error("Error during fetchWithToken request:", error);
          setIsErrorPopupOpen(true);
          handleDropFile(null);
        }
      });
      setOnMessage((event) => {
        const data = JSON.parse(event.data);
        if (data.message || data.progress) {
          console.log(`Analysis Progress: ${data.progress}%`);
          setAnalysisProgress(data.progress);
          console.log(`Message: ${data.message}`);
          setProgressMsg(data.message);
        }
        // if (data.result) {
        //     setJsonList(data.result.result);
        //     setIsAnalyzing(false);
        //     console.log("Analysis completed successfully");
        //     console.log("result = %o", data.result.result);
        // }
      });

            setOnError((error) => {
                console.error("WebSocket error:", error);
                setIsErrorPopupOpen(true);
                setIsFetchingData(false);
                setIsAnalyzing(false);
                handleDropFile(null);
            });

            setOnClose(() => {
                console.log("WebSocket closed");
                setIsFetchingData(false);
                setIsAnalyzing(false);
            });
        } catch (error) {
            console.error("Error uploading audio:", error);
            setIsErrorPopupOpen(true);
            handleDropFile(null);
        }

        await connectWebSocket();
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            handleDropFile(file);
        }
    };
    console.log("jsonList.length = ", jsonList.length);
    return (
        <div>
            <Dropzone onDrop={handleDrop} />
            <button
                className={`w-full bg-emerald-500 text-white py-2 rounded-xl font-semibold text-lg hover:bg-emerald-600 transition duration-300 mb-4 flex items-center justify-center ${
                    isFetchingData ? "opacity-75 cursor-not-allowed" : ""
                }`}
                disabled={isFetchingData || !audioBlob}
                onClick={handleOpenPopup}
            >
                {isFetchingData ? (
                    <>
                        <Activity className="w-5 h-5 mr-2 animate-spin" />
                        분석 중...
                    </>
                ) : (
                    <>
                        <Users className="w-5 h-5 mr-2" />
                        Start Analysis
                    </>
                )}
            </button>

            {/* 파일 업로드 프로그레스 바 */}
            {isUploadingData && <ProgressBar progressData={uploadProgress} />}

            {/* 분석 프로그레스 바 */}
            {isAnalyzing && (
                <ProgressBar progressData={analysisProgress} progressMsg={progressMsg} />
            )}

            <SelectPeoplePopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSelect={handleConfirm}
            />
            <ErrorPopup isOpen={isErrorPopupOpen} onClose={() => setIsErrorPopupOpen(false)} />
            {jsonList.length > 0 && <TableList jsonList={jsonList} />}
            {/* </ResultContainer> */}
            <div ref={resultRef}></div>
        </div>
    );
};

export default EmotionRecognition;

export const ProgressBar = ({ progressData, progressMsg }) => {
    const [dotProgress, setDotProgress] = useState(0);

    useEffect(() => {
        console.log("progressData = %o", progressData);
        console.log("progressMsg = %o", progressMsg);

        const interval = setInterval(() => {
            setDotProgress((prevProgress) => (prevProgress + 1) % 3);
        }, 300);

        return () => clearInterval(interval);
    }, [progressData, progressMsg]);

    return (
        <div className="mt-4 w-full">
            {/* 프로그래스 바 컨테이너 */}
            <div className="bg-gray-200 rounded-full h-6 relative overflow-hidden w-full">
                {/* 프로그래스 바 */}
                <div
                    className="bg-emerald-500 h-full rounded-full relative overflow-hidden"
                    style={{ width: `${progressData}%` }}
                >
                    <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-75 animate-shimmer"></div>
                </div>
                {/* 퍼센트 텍스트 */}
                <span className="absolute top-0 left-1/2 transform -translate-x-1/2 text-white font-bold text-sm h-full flex items-center justify-center">
                    {`${progressData}%`}
                </span>
            </div>

            {/* 3개의 점 인디케이터 */}
            <div className="flex items-center justify-center space-x-2 mt-2">
                {[0, 1, 2].map((index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${
                            dotProgress === index ? "bg-emerald-500" : "bg-gray-300"
                        } transition-colors duration-300`}
                    ></div>
                ))}
            </div>

            {/* 프로그레스 메시지 */}
            {progressMsg && (
                <div className="text-emerald-500 font-bold mt-2 text-center">{progressMsg}</div>
            )}
        </div>
    );
};
