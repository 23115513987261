import { globalModalConstant } from "../Constant";

/**
 *  @param {bool} modalIsOpen  전역모달 오픈여부
 *  @param {obj} modalProps  전역모달 컴포넌트에 전달할 props
 *  @param {obj} modalComponent  전역모달에 사용할 컴포넌트
 *  @param {string} prevFocusKey  전역모달에서 모달 닫을시 이전으로 돌아갈 포커스키명
 *  @param {function} onAfterOpen  전역 모달에서 모달 오픈 시 동작하는 함수, react-modal onAfterOpen Wrapper
 *  @param {function} onAfterClose  전역 모달에서 모달 닫을 시 동작하는 함수, react-modal onAfterClose Wrapper
 *
 */

const defaultState = {
  modalIsOpen: false,
  modalProps: null,
  modalComponent: null,
  onAfterOpen: null,
  onAfterClose: null,
  modalType: "normal",
};

export function globalModal(state = defaultState, action) {
  switch (action.type) {
    case globalModalConstant.GLOBALMODAL:
      return {
        ...state,
        ...action.value,
        ...(!action.value.modalIsOpen ? { modalType: "normal" } : ""),
      };
    default:
      return state;
  }
}
