/*
 * global modal 상수
 */

export const globalModalConstant = {
  GLOBALMODAL: "GLOBALMODAL",
  INIT_GLOBALMODAL: "INIT_GLOBALMODAL",
  GLOBAL_ROUTINE_MODAL: "GLOBAL_ROUTINE_MODAL",
  GLOBAL_CHANGENAME_MODAL: "GLOBAL_CHANGENAME_MODAL",
  GLOBAL_JOIN_MODAL: "GLOBAL_JOIN_MODAL",
};
