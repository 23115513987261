import React, { useState, useEffect } from "react";

function ServerStatus() {
    const [isConnected, setIsConnected] = useState(false);
    const [websocket, setWebSocket] = useState(null);

    useEffect(() => {
        const connectWebSocket = () => {
            const ws = new WebSocket(
                `${process.env.REACT_APP_WEB_SOCKET_URL}/api/ws/ping`
                // `wss://${process.env.REACT_APP_API_DOMAIN}/api/ws`
            );

            ws.onopen = () => {
                console.log("웹소켓 연결됨");
                setIsConnected(true);
            };

            ws.onclose = () => {
                console.log("웹소켓 연결 끊김");
                setIsConnected(false);
                setTimeout(() => connectWebSocket(), 5000);
            };

            ws.onmessage = () => {
                console.log("수신된 메세지가 있음");
            };

            setWebSocket(ws);
        };

        connectWebSocket();

        return () => {
            if (websocket) {
                websocket.close();
            }
        };
    }, []);

    const getStatusColor = () => {
        return isConnected ? "bg-green-500" : "bg-red-500";
    };

    return (
        <div className="flex items-center space-x-2 justify-end mb-4">
            <div className={`w-2 h-2 rounded-full ${getStatusColor()}`}></div>
            <div className="text-sm font-medium text-gray-800">
                {isConnected ? "Connection Success" : "Connection Failed"}
            </div>
        </div>
    );
}

export default ServerStatus;
