import React, { useState } from "react";

import AudioRecorder from "./AudioRecorder";
import EmotionRecognition from "./EmotionRecognition";

import AudioPlayer from "./AudioPlayer";
import { WebSocketProvider } from "./WebSocketProvider";
import ServerStatus from "./ServerStatus";

function MainPage() {
  const [audioBlob, setAudioBlob] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  const handleRecordingComplete = (blob) => {
    setAudioBlob(blob);
    setCurrentTime(null);
  };

  const handleRecording = (currentTime) => {
    setCurrentTime(currentTime);
    setAudioBlob(null);
  };

  const handleDropFile = (blob) => {
    setAudioBlob(blob);
  };

  return (
    <div className="bg-white min-h-screen p-4 flex items-center justify-center">
      <div
        className="max-w-md w-full bg-white rounded-2xl p-4"
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
      >
        <ServerStatus />
        <h1 className="text-3xl font-bold text-center text-emerald-700 mb-4">
          Round Note
        </h1>
        <AudioRecorder
          onRecordingComplete={handleRecordingComplete}
          onRecording={handleRecording}
        />
        {audioBlob && <AudioPlayer audioBlob={audioBlob} />}

        <WebSocketProvider>
          <EmotionRecognition
            audioBlob={audioBlob}
            handleDropFile={handleDropFile}
          />
        </WebSocketProvider>
      </div>
    </div>
  );
}

export default MainPage;
