import React, { createContext, useContext } from 'react';
import axios from 'axios';

import {
    getAccessToken,
    isTokenExpired,
    refreshAccessToken,
    verifyAccessToken
} from '../Utils/TokenManager'

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {

    const fetchWithToken = async (url, options = {}) => {
        let token = getAccessToken();

        if (isTokenExpired(token) || !(await verifyAccessToken(token))) {
            token = await refreshAccessToken();
            if (!token) {
                throw new Error("Unable to refresh token. Please login again.");
            }
        }

        const response = await axios({
            url,
            method: options.method || 'get', // default method is GET
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            },
            data: options.data || null, // use `data` for POST/PUT requests
            params: options.params || null, // use `params` for query parameters
            withCredentials: options.withCredentials || false,
            onUploadProgress: options.onUploadProgress || null,
        });

        console.log('fetchWithToken Used:', url);

        return response;
    };

    const fetchWithoutToken = async (url, options = {}) => {
        const response = await axios({
            url,
            method: options.method || 'get',
            headers: {
                ...options.headers,
            },
            data: options.data || null,
            params: options.params || null,
            withCredentials: options.withCredentials || false,
        });

        console.log('fetchWithoutToken Used:', url);

        return response;
    };

    return (
        <ApiContext.Provider value={{ fetchWithToken, fetchWithoutToken }}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    return useContext(ApiContext);
};
