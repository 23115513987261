import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./components/MainPage";
import { AppProvider } from "./AppContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { ApiProvider } from "./components/ApiProvider";
import LoginPage from "./components/LoginPage";

function App() {
  return (
    <AppProvider>
      <Router>
        <ApiProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/main"
                element={
                  <ProtectedRoute>
                    <MainPage />
                  </ProtectedRoute>
                }
              />
              </Routes>
          </div>
        </ApiProvider>
      </Router>
    </AppProvider>
    )
}

export default App;
