import { globalModalConstant } from "../Constant";

/**
 * global modal 액션
 */

export const globalModalActions = {
  globalModalValueSet
};

function globalModalValueSet(value) {
  return { type: globalModalConstant.GLOBALMODAL, value };
}
