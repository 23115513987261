import { globalModalActions } from "../Action";

export const handleGetUserMediaError = (error, dispatch, setIsRecording) => {
  let errorMsg = "";

  switch (error.name) {
    case "NotAllowedError":
    case "PermissionDeniedError":
      errorMsg = "오디오 녹음 권한이 거부되었습니다. 권한을 허용해주세요.";
      break;
    case "NotFoundError":
    case "DevicesNotFoundError":
      errorMsg = "오디오 장치를 찾을 수 없습니다.";
      break;
    case "NotReadableError":
    case "TrackStartError":
      errorMsg = "오디오 장치를 사용할 수 없습니다.";
      break;
    default:
      errorMsg =
        "오디오 장치를 활성화하는 동안 오류가 발생했습니다. 다시 시도해주세요.";
      break;
  }

  dispatch(
    globalModalActions.globalModalValueSet({
      modalIsOpen: true,
      modalType: "normal",
      modalComponent: () => (
        <div style={{ marginTop: "15px" }}>
          <p>{errorMsg}</p>
        </div>
      ),
    })
  );

  setIsRecording(false);
};

export const handleGetLoginError = (error, setErrorMsg, setLoginFailed) => {
  switch (error.code) {
    case "ERR_BAD_REQUEST":
      setErrorMsg(
        "사용자이름 또는 비밀번호가 올바르지 않습니다. 정확히 입력해 주세요."
      );
      break;
    case "ERR_NETWORK":
      setErrorMsg("네트워크가 연결되지 않았습니다. 잠시 후 다시 시도해주세요.");
      break;
    default:
      setErrorMsg(
        "사용자이름 또는 비밀번호가 올바르지 않습니다. 정확히 입력해 주세요."
      );
      break;
  }

  setLoginFailed(true);
};
