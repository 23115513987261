export const getAccessToken = () => sessionStorage.getItem("accessToken");
const setAccessToken = (token) => sessionStorage.setItem("accessToken", token);
const removeAccessToken = () => sessionStorage.removeItem("accessToken");

export const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiry = payload.exp * 1000;
    return Date.now() > expiry;
};

export const verifyAccessToken = async (token) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/verify-token`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.ok) {
            const data = await response.json();
            console.log("verify token result:", data);
            return data.isValid;
        } else {
            console.log("verify token result: receive fail response");
            return false;
        }
    } catch (error) {
        console.error("Error verify token:", error);
        removeAccessToken();
        return false;
    }
};

export const refreshAccessToken = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
            {
                method: "POST",
                credentials: "include",
            }
        );
        if (response.ok) {
            const data = await response.json();
            setAccessToken(data.access_token);
            return data.access_token;
        } else {
            throw new Error("Failed to refresh token");
        }
    } catch (error) {
        console.error("Error refreshing token:", error);
        removeAccessToken();
        return null;
    }
};
