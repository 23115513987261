import React from "react";
import { Navigate } from "react-router-dom";
// import { useAuth } from "../AppContext";

const ProtectedRoute = ({ children }) => {
  // const { isAuthenticated } = useAuth();

  const isAuthenticated = document.cookie
    .split('; ')
    .find(row => row.startsWith('is_authenticated'))
    ?.split('=')[1];

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  
  console.log('로그인 시간: ', isAuthenticated)

  return children;
};

export default ProtectedRoute;
