import { useState } from "react";

const SelectPeoplePopup = ({ isOpen, onClose, onSelect }) => {
  const [participantCount, setParticipantCount] = useState(0);

  const handleSelect = () => {
    onSelect(participantCount);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-6 w-80">
        <h2 className="text-2xl font-bold text-emerald-700 mb-4">
          대화 참여자 수
        </h2>
        <div className="flex items-center justify-between mb-6">
          <button
            className="w-10 h-10 rounded-full bg-emerald-100 text-emerald-700 flex items-center justify-center"
            onClick={() => setParticipantCount((prev) => Math.max(0, prev - 1))}
          >
            -
          </button>
          <div className="text-3xl font-bold text-emerald-700">
            {participantCount == 0 ? "Auto" : participantCount}
          </div>
          <button
            className="w-10 h-10 rounded-full bg-emerald-100 text-emerald-700 flex items-center justify-center"
            onClick={() =>
              setParticipantCount((prev) => Math.min(10, prev + 1))
            }
          >
            +
          </button>
        </div>
        <button
          className="w-full bg-emerald-500 text-white py-3 rounded-xl font-semibold text-lg hover:bg-emerald-600 transition duration-300"
          onClick={handleSelect}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default SelectPeoplePopup;
