import React, {
    createContext,
    useContext,
    useRef,
    useState,
    useCallback,
    useEffect,
} from "react";
import {
    getAccessToken,
    isTokenExpired,
    refreshAccessToken,
    verifyAccessToken,
} from "../Utils/TokenManager";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const webSocketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const eventHandlers = useRef({
        onOpen: null,
        onClose: null,
        onError: null,
        onMessage: null,
    });

    const connectWebSocket = useCallback(async () => {
        try {
            let token = getAccessToken();
            console.log("토큰:", token);

            if (isTokenExpired(token) || !(await verifyAccessToken(token))) {
                token = await refreshAccessToken();
                if (!token) {
                    throw new Error(
                        "Unable to refresh token. Please login again."
                    );
                }
            }

            webSocketRef.current = new WebSocket(
                `${process.env.REACT_APP_WEB_SOCKET_URL}/api/ws/emotion?token=${token}`
            );

            webSocketRef.current.onopen = () => {
                console.log("소켓 연결");
                setIsConnected(true);
                if (eventHandlers.current.onOpen)
                    eventHandlers.current.onOpen();
            };

            webSocketRef.current.onclose = (event) => {
                console.log("소켓 연결 해제");
                console.log("해제 사유:", event)

                setIsConnected(false);
                if (eventHandlers.current.onClose)
                    eventHandlers.current.onClose(event);
                webSocketRef.current = null;
            };

            webSocketRef.current.onerror = (error) => {
                console.log("소켓 에러 발생:", error);
                if (eventHandlers.current.onError)
                    eventHandlers.current.onError(error);
            };

            webSocketRef.current.onmessage = (event) => {
                console.log("server -> client : 데이터 수신:", event.data);
                if (eventHandlers.current.onMessage)
                    eventHandlers.current.onMessage(event);
            };

            return true;
        } catch (error) {
            console.log("WebSocket 연결 실패: ", error);
            return false;
        }
    }, []);

    const disconnectWebSocket = useCallback(() => {
        if (webSocketRef.current) {
            webSocketRef.current.close();
        }
    }, []);

    const sendMessage = useCallback((message) => {
        if (
            webSocketRef.current &&
            webSocketRef.current.readyState === WebSocket.OPEN
        ) {
            webSocketRef.current.send(message);
        } else {
            console.error("WebSocket is not connected.");
        }
    }, []);

    useEffect(() => {
        return () => {
            disconnectWebSocket();
        };
    }, [disconnectWebSocket]);

    const setEventHandler = useCallback((eventName, callback) => {
        eventHandlers.current[eventName] = callback;
    }, []);

    const contextValue = {
        isConnected,
        connectWebSocket,
        disconnectWebSocket,
        sendMessage,
        setOnOpen: (callback) => setEventHandler("onOpen", callback),
        setOnClose: (callback) => setEventHandler("onClose", callback),
        setOnError: (callback) => setEventHandler("onError", callback),
        setOnMessage: (callback) => setEventHandler("onMessage", callback),
    };

    return (
        <WebSocketContext.Provider value={contextValue}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return context;
};
