// Dropzone.js
import { Upload } from "lucide-react";
import React from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "audio/wav": [".wav"],
        },
        onDrop: (acceptedFiles) => {
            const firstFile = acceptedFiles[0];
            if (
                firstFile &&
                firstFile.type === "audio/wav" &&
                firstFile.name.endsWith(".wav")
            ) {
                onDrop([firstFile]);
            } else {
                alert(
                    "유효하지 않은 파일 형식입니다. .wav 파일을 업로드해주세요."
                );
            }
        },
    });

    return (
        <div className="mb-4">
            <label
                htmlFor="fileInput"
                className="block w-full py-2 px-4 rounded-xl border-2 border-dashed border-emerald-300 text-center cursor-pointer hover:bg-emerald-50 transition duration-300"
                {...getRootProps()}
            >
                <Upload className="w-6 h-6 text-emerald-500 inline-block mr-2" />
                <input {...getInputProps()} />
                <span className="text-emerald-600">Upload WAV File</span>
            </label>
        </div>
    );
};

export default Dropzone;
