import React, { useState } from "react";

const roleOptions = [
    "환자",
    "의사",
    "간호사",
    "보호자",
    "미확인",
    // ...Array.from({ length: 7 }, (_, i) => `보호자${i + 1}`),
];

const TableList = ({ jsonList }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [customRoles, setCustomRoles] = useState({});

    const handleRoleChange = (index, value) => {
        setCustomRoles((prev) => ({ ...prev, [index]: value }));
    };

    console.log("jsonList = %o", jsonList);

    return (
        <div className="bg-emerald-50 rounded-xl p-6">
            <div className="flex space-x-2 mb-3 overflow-x-auto scrollbar-hide">
                {jsonList.map((item, index) => (
                    <button
                        className={`flex-none min-w-[100px] py-2 px-4 rounded-full font-medium transition duration-300 ${
                            activeIndex === index
                                ? "bg-emerald-500 text-white"
                                : "bg-white text-emerald-700 hover:bg-emerald-100"
                        }`}
                        key={index}
                        onClick={() => setActiveIndex(index)}
                        active={activeIndex === index}
                    >
                        {/* {customRoles[index] || roleOptions[index]} */}
                        {roleOptions.includes(item.Role) ? item.Role : "미확인"}
                    </button>
                ))}
            </div>

            {jsonList.map(
                (item, index) =>
                    index === activeIndex && (
                        <div className="space-y-4">
                            <div className="flex justify-between items-center bg-white p-3 rounded-xl shadow-sm">
                                <span className="font-semibold text-emerald-700">화자</span>
                                <div className="flex items-center bg-emerald-100 px-3 py-1 rounded-full">
                                    <select
                                        value={
                                            roleOptions.includes(item.Role) ? item.Role : "미확인"
                                        }
                                        // value={
                                        //   customRoles[index] ||
                                        //   roleOptions[index % roleOptions.length]
                                        // }
                                        onChange={(e) => handleRoleChange(index, e.target.value)}
                                        className="mr-2 text-emerald-700 bg-emerald-100 border-none outline-none"
                                    >
                                        {roleOptions.map((role, i) => (
                                            <option key={i} value={role}>
                                                {role}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {Object.entries(item)
                                .filter(([key]) =>
                                    ["Categorization", "Emotion", "Description", "text"].includes(
                                        key
                                    )
                                )
                                .map(([key, value]) => (
                                    <div key={key} className="bg-white p-3 rounded-xl shadow-sm">
                                        <div className="font-semibold text-emerald-700 mb-1">
                                            {key}
                                        </div>
                                        <div className="text-gray-600">{value}</div>
                                    </div>
                                ))}
                        </div>
                    )
            )}
        </div>
    );
};

export default TableList;
